<template>
  <div class="header-warp" @click="goHome">
    <img src="../assets/logo.png" alt="" />
    {{ $t("title", { logoName: logoName }) }}
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "navHeader",
  components: {},
  computed: {
    ...mapState(["logoName"]),
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@media only screen and (min-width: 751px) {
  .header-warp {
    display: flex;
    align-items: center;
    font-family: Roboto, Roboto;
    font-weight: bold;
    font-size: 28px;
    color: #3071f2;
    cursor: pointer;
    img {
      width: 60px;
      margin-right: 8px;
    }
  }
}
@media only screen and (max-width: 750px) {
  .header-warp {
    display: flex;
    align-items: center;
    font-family: Roboto, Roboto;
    font-weight: bold;
    font-size: 17px;
    color: #3071f2;
    padding-top: calc(
      10px + constant(safe-area-inset-top, 20px)
    ); /* Older iOS */
    padding-top: calc(10px + env(safe-area-inset-top, 20px)); /* Newer iOS */
    img {
      width: 40px;
      margin-right: 5px;
    }
  }
}
</style>
