<template>
  <div>
    <Index v-if="!loading" />
  </div>
</template>

<script>
import Index from "../components/index.vue";

export default {
  name: "Home",
  data() {
    return {
      loading: true,
    };
  },
  components: {
    Index,
  },
  mounted() {
    this.$nextTick(()=>{
      this.loading = false
    })
  },
  methods: {
  },
};
</script>

