import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    logoName: '',
    domainInfo: {
      "code": "",
      "title": "",
      "supportDomain": ""
    },
  },
  getters: {
  },
  mutations: {
    CHANGE_TITLE(state, title) {
      document.title = title
      state.logoName = title
    },
    CHANGE_DOMAIN(state, item) {
      state.domainInfo = item
    },
  },
  actions: {
  },
  modules: {
  }
})
