import request from '@/utils/axios'

// 获取聊天记录
export function getService(data = {}) {
 let params = localStorage.getItem("query");
 if (params) {
  params = JSON.parse(params);
 } else {
  params = {}
 }
 data.metadata = { ...params }
 return request({
  url: '/support/chat',
  method: 'POST',
  data
 })
}
//邮箱查询
export function supportEmail(data = {}) {
 let params = localStorage.getItem("query");
 if (params) {
  params = JSON.parse(params);
 } else {
  params = {}
 }
 data.metadata = { ...params }
 return request({
  url: '/support/email',
  method: 'POST',
  data
 })
}
//站点信息获取
export function supportInfo(data = {}) {
 let params = localStorage.getItem("query");
 if (params) {
  params = JSON.parse(params);
 } else {
  params = {}
 }
 data.metadata = { ...params }
 return request({
  url: '/support/info',
  method: 'POST',
  data
 })
}
//交易信息查询
export function transactionInfo(data = {}) {
 let params = localStorage.getItem("query");
 if (params) {
  params = JSON.parse(params);
 } else {
  params = {}
 }
 data.metadata = { ...params }
 return request({
  url: '/support/transaction/info',
  method: 'POST',
  data
 })
}
