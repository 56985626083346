var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"warp"},[_c('div',{staticClass:"index-box"},[_c('navHeader'),_c('div',{staticClass:"email-checked-warp"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("emailChecked.title")))]),_c('div',{staticClass:"sub-title"},[_vm._v(" "+_vm._s(_vm.$t("emailChecked.subscription"))+" ")]),_c('div',{staticClass:"email-checked-box"},[_c('div',{staticClass:"email-checked-box-title"},[_vm._v(" "+_vm._s(_vm.$t("emailChecked.subtitle"))+" ")]),_c('el-form',{ref:"form",attrs:{"label-position":"top","model":_vm.form}},[_c('el-form-item',{attrs:{"prop":"email","rules":[
              {
                required: true,
                message: _vm.$t('emailChecked.emailMessage.tips1'),
                trigger: 'blur',
              },
              {
                type: 'email',
                message: _vm.$t('emailChecked.emailMessage.tips2'),
                trigger: ['blur', 'change'],
              },
            ]}},[_c('el-input',{attrs:{"autocomplete":"off"},on:{"blur":_vm.checkedEmail},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('el-button',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"btn",attrs:{"type":"primary","disabled":_vm.disabled},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("emailChecked.btn")))])],1)],1),_c('div',{staticClass:"footer-des"},[_vm._v(" "+_vm._s(_vm.$t("emailChecked.tips"))+" ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }