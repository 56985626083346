<template>
  <div class="warp">
    <div class="index-box">
      <navHeader />
      <div class="email-checked-warp">
        <div class="title">{{ $t("payOrder.title") }}</div>
        <div class="sub-title">
          {{ $t("payOrder.subscription") }}
        </div>
        <div class="email-checked-box">
          <div class="email-checked-box-warp">
            <el-form label-position="top" :model="form" ref="form">
              <el-form-item
                prop="method"
                class="form-item"
                :label="$t('payOrder.message.payment')"
                :rules="[
                  {
                    required: true,
                    message: $t('payOrder.message.tips'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-select
                  v-model="form.method"
                  @change="checkedEmail"
                  :placeholder="$t('payOrder.message.tips2')"
                >
                  <el-option
                    v-for="(item, index) in list"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                prop="transactionId"
                class="form-item"
                :label="$t('payOrder.message.transaction')"
                :rules="[
                  {
                    required: true,
                    message: $t('payOrder.message.tips3'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="form.transactionId"
                  @blur="checkedEmail"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <div slot="label" class="item-label">
                  {{ $t("payOrder.previewTitle") }}
                </div>
                <div class="preview-list">
                  <div
                    class="preview-box"
                    v-for="(item, index) in payList"
                    :key="index"
                  >
                    <el-image
                      :src="item"
                      class="image-box"
                      :preview-src-list="[item]"
                    >
                    </el-image>
                    <div class="preview-icon">{{ $t("payOrder.preview") }}</div>
                  </div>
                </div>
              </el-form-item>
              <el-button
                @click="submit"
                type="primary"
                class="btn"
                v-loading="loading"
                >{{ $t("payOrder.btn") }}</el-button
              >
              <div class="tips" v-if="tipsShow">
                {{ $t("payOrder.tips") }}
              </div>
            </el-form>
          </div>
        </div>
        <div class="footer-des">
          {{ $t("payOrder.tips2") }}
        </div>
      </div>
      <Chat ref="chat" :title="$t('title')" />
    </div>
  </div>
</template>

<script>
import { transactionInfo } from "@/api/index";
import { mapState } from "vuex";

export default {
  name: "EmailChecked",
  data() {
    return {
      loading: false,
      disabled: true,
      tipsShow:false,
      transactionCode:'',
      form: {
        method: "",
        transactionId: "",
      },
      list: [
        {
          label: "DANA",
          value: "dana",
          img: "/images/dana.png",
        },
        {
          label: "GCASH",
          value: "g_cash",
          img: "/images/gcash.png",
        },
      ],
    };
  },
  components: {},
  computed: {
    ...mapState(["logoName", "domainInfo"]),
    payList() {
      return this.list.map((res) => res.img);
    },
  },
  mounted() {},
  methods: {
    sentEmail() {
      this.loading = true;
      transactionInfo({
        supportCode: this.domainInfo.code,
        type: this.form.method,
        transactionCode: this.form.transactionId,
      })
        .then((res) => {
          this.loading = false;
          if (res.obj.transactionCode) {
            this.tipsShow = false
            this.transactionCode = res.obj.transactionCode
            localStorage.removeItem("messageId");
            let params = localStorage.getItem("query");
            if (params) {
              params = JSON.parse(params);
            } else {
              params = {}
            }
            params.transactionCode = res.obj.transactionCode;
            localStorage.setItem('query',JSON.stringify(params))
            setTimeout(()=>{
            this.$refs.chat.openChat(res.obj.transactionCode);
            },100)
          }else{
            this.tipsShow = true
          }
        })
        .catch((e) => {
          this.loading = false;
            this.tipsShow = true
        });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.disabled = false;
          this.sentEmail();
        } else {
          this.disabled = true;
        }
      });
    },
    checkedEmail() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.disabled = false;
        } else {
          this.disabled = true;
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media only screen and (min-width: 751px) {
  .email-checked-warp {
    width: 686px;
    .email-checked-box-warp {
      width: 486px;
    }
    .title {
      font-weight: 600;
      font-size: 36px;
      color: #1f2021;
    }
    .sub-title {
      font-size: 18px;
      color: #1f2021;
      margin-top: 16px;
    }
    .email-checked-box {
      margin-top: 20px;
      padding: 24px 24px;
      background: rgba(255, 255, 255, 0.6);
      box-shadow: 0px 12px 36px 0px rgba(0, 0, 0, 0.1);
      border-radius: 12px 12px 12px 12px;
      padding-bottom: 32px;
      .form-item {
        margin-bottom: 16px !important;
      }
      .preview-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        .preview-box {
          position: relative;
          width: 90px;
          flex: 0 0 90px;
          height: 90px;
          .image-box {
            width: 100%;
            height: 100%;
          }
          .preview-icon {
            pointer-events: none; /* This allows click to pass through */
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            background: rgba(0, 0, 0, 0.5);
            font-weight: 600;
            font-size: 13px;
            color: #ffffff;
          }
        }
      }
      :deep(.el-form) {
        .el-form-item {
          margin-bottom: 0;
          &:last-child {
            margin-bottom: 0;
          }
          .el-form-item__label {
            font-weight: 500;
            font-size: 18px;
            color: #1f2021;
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
          }
        }
      }
      .item-label {
        font-weight: 400;
        font-size: 14px;
        color: #919599;
      }
      .email-checked-box-title {
        font-family: Roboto, Roboto;
        font-weight: 500;
        font-size: 24px;
        color: #1f2021;
        margin-bottom: 18px;
      }
      :deep(.el-input__inner) {
        // background: #e7ebec;
        height: 58px;
        border-radius: 12px;
      }
      :deep(.el-select) {
        width: 100%;
      }
      :deep(.el-form-item__label) {
        padding-bottom: 0;
      }
      .btn {
        margin-top: 22px;
        height: 60px;
        border-radius: 12px;
        width: 200px;
      }
    }
    .footer-des {
      font-size: 16px;
      color: #1f2021;
      margin-top: 43px;
      padding-bottom: 30px;
    }
  }
}
@media only screen and (max-width: 750px) {
  .email-checked-warp {
    width: 100%;
    .title {
      font-weight: 600;
      font-size: 22px;
      color: #1f2021;
      margin-top: 15px;
    }
    .sub-title {
      font-size: 15px;
      color: #1f2021;
      margin-top: 10px;
    }
    .email-checked-box {
      margin-top: 20px;
      padding: 20px 15px;
      background: rgba(255, 255, 255, 0.6);
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 12px 12px 12px 12px;
      padding-bottom: 36px;
      .form-item {
        margin-bottom: 16px !important;
      }
      .preview-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        .preview-box {
          position: relative;
          width: 90px;
          flex: 0 0 90px;
          height: 90px;
          .image-box {
            width: 100%;
            height: 100%;
          }
          .preview-icon {
            pointer-events: none; /* This allows click to pass through */
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            background: rgba(0, 0, 0, 0.5);
            font-weight: 600;
            font-size: 13px;
            color: #ffffff;
          }
        }
      }
      :deep(.el-form) {
        .el-form-item {
          margin-bottom: 0;
          &:last-child {
            margin-bottom: 0;
          }
          .el-form-item__label {
            font-weight: 500;
            font-size: 17px;
            color: #1f2021;
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
          }
        }
      }
      .item-label {
        font-weight: 400;
        font-size: 13px;
        color: #919599;
      }
      .email-checked-box-title {
        font-weight: 500;
        font-size: 17px;
        color: #1f2021;
        margin-bottom: 12px;
      }
      :deep(.el-input__inner) {
        // background: #e7ebec;
        border-radius: 16px;
        height: 44px;
      }
      :deep(.el-select) {
        width: 100%;
      }
      :deep(.el-form-item__label) {
        padding-bottom: 0;
      }
      .btn {
        width: 100%;
        margin-top: 22px;
        border-radius: 16px;
        height: 44px;
      }
    }
    .footer-des {
      font-size: 14px;
      color: #1f2021;
      margin-top: 24px;
    }
  }
}
</style>