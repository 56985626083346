<template>
  <div class="warp">
    <div class="index-box">
      <navHeader />
      <div class="email-checked-warp">
        <div class="title">{{ $t("verifyEmail.title") }}</div>
        <div class="sub-title">
          {{
            $t("verifyEmail.subscription", {
              email: $route.query.email,
            })
          }}
        </div>
        <div class="email-checked-box">
          <div class="email-checked-box-title">
            <div :key="index" v-for="(item, index) in $t('verifyEmail.list')">
              {{ item }}
            </div>
          </div>
          <el-button @click="submit" type="primary" class="btn">{{
            $t("verifyEmail.btn")
          }}</el-button>
        </div>
        <div class="footer-des">
          {{ $t("verifyEmail.tips")
          }}<a href="#" @click.stop="goPage">{{
            $t("verifyEmail.hrefTitle")
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { supportEmail } from "@/api/index";
import { mapState } from "vuex";

export default {
  name: "verifyEmail",
  data() {
    return {
      loading: false,
      disabled: true,
      form: {
        email: "",
      },
    };
  },

  computed: {
    ...mapState(["logoName", "domainInfo"]),
  },
  components: {},
  mounted() {},
  methods: {
    submit() {
      this.$router.push("/emailChecked");
    },
    goPage() {
      this.$router.push("/payOrder");
    },
  },
};
</script>

<style lang="less" scoped>
@media only screen and (min-width: 751px) {
  .email-checked-warp {
    max-width: 686px;
    margin-top: 40px;
    .title {
      font-weight: 600;
      font-size: 36px;
      color: #1f2021;
    }
    .sub-title {
      font-size: 18px;
      color: #1f2021;
      margin-top: 16px;
    }
    .email-checked-box {
      margin-top: 30px;
      padding: 24px 24px;
      background: rgba(255, 255, 255, 0.6);
      box-shadow: 0px 12px 36px 0px rgba(0, 0, 0, 0.1);
      border-radius: 12px 12px 12px 12px;
      padding-bottom: 39px;
      .email-checked-box-title {
        font-weight: 400;
        font-size: 18px;
        color: #1f2021;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      :deep(.el-input__inner) {
        // background: #e7ebec;
        height: 62px;
        border-radius: 12px;
      }
      .btn {
        margin-top: 24px;
        height: 60px;
        border-radius: 12px;
      }
    }
    .footer-des {
      font-size: 16px;
      color: #1f2021;
      margin-top: 43px;
      a {
        color: #1f2021;
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .email-checked-warp {
    width: 100%;
    .title {
      font-weight: 600;
      font-size: 22px;
      color: #1f2021;
      margin-top: 20px;
    }
    .sub-title {
      font-size: 15px;
      color: #1f2021;
      margin-top: 10px;
    }
    .email-checked-box {
      margin-top: 20px;
      padding: 20px 15px;
      background: rgba(255, 255, 255, 0.6);
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 12px 12px 12px 12px;
      padding-bottom: 25px;
      .email-checked-box-title {
        font-weight: 400;
        font-size: 15px;
        color: #1f2021;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      :deep(.el-input__inner) {
        // background: #e7ebec;
        border-radius: 16px;
        height: 44px;
      }
      .btn {
        width: 100%;
        margin-top: 22px;
        border-radius: 16px;
        height: 44px;
      }
    }
    .footer-des {
      font-size: 14px;
      color: #1f2021;
      margin-top: 24px;
      a {
        color: #1f2021;
      }
    }
  }
}
</style>