<template>
  <div class="index-warp">
    <div class="index-box">
      <navHeader />
      <div class="index-content-title">
        {{ $t("index.title", { logoName: logoName }) }}
      </div>
      <div class="index-content">
        <div class="flex-1 index-content-left">
          <div class="des-title">{{ $t("index.desLeft.title") }}</div>
          <div class="des-list">
            <div
              class="des-item"
              v-for="(item, index) in $t('index.desLeft.list')"
              :key="index"
            >
              <img :src="icons(item.icon)" alt="" />
              {{ item.title }}
            </div>
          </div>
          <div class="btn" @click="goPage('/emailChecked')">
            {{ $t("index.desLeft.btn") }}
          </div>
        </div>
        <div class="flex-1 index-content-right">
          <div class="des-title">
            {{ $t("index.desRight.title", { logoName: logoName }) }}
          </div>
          <div class="des-list">
            <div
              v-for="(item, index) in $t('index.desRight.list')"
              :key="index"
            >
              {{ item.replace("{logoName}", logoName) }}
            </div>
          </div>
          <div class="btn" @click="goPage('/payOrder')">
            {{ $t("index.desRight.btn") }}
          </div>
        </div>
      </div>
      <div class="index-footer">
        <div class="footer-title">
          {{ $t("index.desc.title", { logoName: logoName }) }}
        </div>
        <div class="footer-list">
          <div v-for="(item, index) in $t('index.desc.list')" :key="index">
            {{ item.replace("{logoName}", logoName) }}
          </div>
        </div>
      </div>
    </div>
    <Chat ref="chat" :title="$t('title')" />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "indexWarp",
  components: {},
  computed: {
    descList() {
      return this.$t("index.desc.list");
    },
    transactionCode() {
      return this.$route.query.transactionCode;
    },
    ...mapState(["logoName"]),
  },
  methods: {
    goPage(url) {
      this.$router.push(url);
    },
    icons(item) {
      return require(`../assets/${item}`);
    },
    getChat() {
      this.$refs.chat.openChat();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.transactionCode) {
        localStorage.removeItem("messageId");
        setTimeout(() => {
          this.$refs.chat.openChat(this.$route.query.transactionCode);
        }, 10);
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@media only screen and (min-width: 751px) {
  .index-warp {
    .index-content-title {
      font-family: Roboto, Roboto;
      font-weight: 600;
      font-size: 36px;
      color: #1f2021;
      padding-bottom: 40px;
      padding-top: 46px;
    }
    .index-footer {
      padding-top: 70px;
      .footer-title {
        font-family: Roboto, Roboto;
        font-weight: 500;
        font-size: 24px;
        color: #1f2021;
      }
      .footer-list {
        padding-top: 16px;
        font-family: Roboto, Roboto;
        font-weight: 400;
        font-size: 18px;
        color: #1f2021;
      }
    }
    .index-content {
      display: flex;
      align-items: center;
      gap: 24px;
      .flex-1 {
        flex: 1;
        background: #fff;
        height: 310px;
        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0px 12px 36px 0px rgba(0, 0, 0, 0.1);
        border-radius: 12px 12px 12px 12px;
        border: 2px solid #ffffff;
        padding: 30px 24px;
        .des-title {
          font-family: Roboto, Roboto;
          font-weight: 500;
          font-size: 24px;
          color: #1f2021;
          padding-bottom: 20px;
        }
        .btn {
          margin-top: 24px;
        }
        .des-list {
          font-family: Roboto, Roboto;
          font-weight: 400;
          font-size: 18px;
          color: #1f2021;
        }
        &.index-content-right {
          .des-list {
            display: flex;
            flex-direction: column;
            gap: 20px;
          }
        }
        &.index-content-left {
          .des-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            .des-item {
              display: flex;
              align-items: center;
              font-family: Roboto, Roboto;
              font-weight: 400;
              font-size: 18px;
              color: #1f2021;
              margin-bottom: 20px;
              img {
                width: 32px;
                margin-right: 10px;
              }
            }
          }
          .btn {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .index-warp {
    .index-content-title {
      font-family: Roboto, Roboto;
      font-weight: 600;
      font-size: 22px;
      color: #1f2021;
      padding-bottom: 15px;
      padding-top: 20px;
    }
    .index-footer {
      width: calc(100% - 15px);
      padding-top: 24px;
      .footer-title {
        font-family: Roboto, Roboto;
        font-weight: 500;
        font-size: 18px;
        color: #1f2021;
      }
      .footer-list {
        padding-top: 12px;
        font-family: Roboto, Roboto;
        font-weight: 400;
        font-size: 15px;
        color: #1f2021;
      }
    }
    .index-content {
      display: flex;
      gap: 12px;
      flex-direction: column;
      width: 100%;
      .flex-1 {
        width: calc(100% - 30px);
        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
        border-radius: 12px 12px 12px 12px;
        padding: 20px 15px;
        .des-title {
          font-family: Roboto, Roboto;
          font-weight: 500;
          font-size: 17px;
          color: #1f2021;
          padding-bottom: 15px;
        }
        .btn {
          margin-top: 20px;
        }
        .des-list {
          font-family: Roboto, Roboto;
          font-weight: 400;
          font-size: 18px;
          color: #1f2021;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        &.index-content-left {
          .des-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            .des-item {
              display: flex;
              align-items: center;
              font-family: Roboto, Roboto;
              font-weight: 400;
              font-size: 15px;
              color: #1f2021;
              margin-bottom: 15px;
              img {
                width: 18px;
                margin-right: 10px;
              }
            }
          }
          .btn {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
