//导入模块
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from "@/page/index";
import emailChecked from "@/page/emailChecked";
import Cancel from "@/page/cancel";
import payOrder from "@/page/payOrder";
import verifyEmail from "@/page/verifyEmail";

//注册路由组件
Vue.use(VueRouter);
//编写路由规则
const routes = [
 {
  path: "/",
  name: 'Home',
  component: Home
 },
 {
  path: "/emailChecked",
  name: 'EmailChecked',
  component: emailChecked
 },
 {
  path: "/cancel",
  name: 'Cancel',
  component: Cancel
 },
 {
  path: "/payOrder",
  name: 'payOrder',
  component: payOrder
 },
 {
  path: "/verifyEmail",
  name: 'verifyEmail',
  component: verifyEmail
 },
]
//创建路由
const router = new VueRouter({
 mode: 'history',
 routes
})
//导出
export default router;