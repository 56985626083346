<template>
  <div id="app" class="index-warp" v-if="!loading">
    <template>
      <router-view></router-view>
      <img src="./assets/bg.png" alt="" class="bg" />
      <img src="./assets/h5-bg.png" alt="" class="h5-bg" />
    </template>
  </div>
</template>

<script>
import { supportInfo } from "@/api/index";

export default {
  name: "App",
  components: {},
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.init();
    this.setLocales();
  },
  methods: {
    init() {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        // Check if the "param" key exists in the query parameters
        if (queryParams.size > 0) {
          let query = this.$route.query;
          let params = localStorage.getItem("query");
          if (params) {
            params = JSON.parse(params);
            query = { ...params, ...query };
          }
          localStorage.setItem("query", JSON.stringify(query));
        }
        let supportInfo = localStorage.getItem("supportInfo") || "";
        if (supportInfo) {
          supportInfo = JSON.parse(supportInfo);
          this.loading = false;
          this.$store.commit("CHANGE_TITLE", supportInfo.title);
          this.$store.commit("CHANGE_DOMAIN", supportInfo);
          return;
        }
        this.getinfo();
      } catch (error) {
        console.log(error)
        this.getinfo();
      }
    },
    getinfo() {
      let domain = window.location.origin;
      if (window.location.origin.includes("localhost")) {
        domain = "https://support.phonetracker.cloud";
      }
      supportInfo({ domain: domain }).then((res) => {
        localStorage.setItem("supportInfo", JSON.stringify(res.obj));
        this.loading = false;
        this.$store.commit("CHANGE_TITLE", res.obj.title);
        this.$store.commit("CHANGE_DOMAIN", res.obj);
      });
    },
    setLocales() {
      const localesJson = require.context(
        "./locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
      );
      const locale = localesJson.keys().map((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
          const locale = matched[1];
          return locale;
        }
      });
      const language = navigator.language || navigator.userLanguage || "en";
      if (locale.includes(language)) {
        this.$i18n.locale = language;
      }
    },
  },
};
</script>
<style lang="less">
@media only screen and (min-width: 751px) {
  .index-warp {
    width: 100%;
    height: 100%;
    position: relative;
    min-height: 100vh;
    .index-box {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 40px;
      padding-top: 26px;
    }

    .tips {
      margin-top: 12px;
      font-weight: 400;
      font-size: 16px;
      color: #f38b3f;
    }
    .bg {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: auto;
      z-index: -1;
      height: 100%;
    }
    .h5-bg {
      display: none;
    }
  }
}

@media only screen and (max-width: 750px) {
  .index-warp {
    width: calc(100% - 30px);
    height: 100%;
    min-height: 100vh;
    padding: 0 15px;

    .tips {
      margin-top: 15px;
      font-weight: 400;
      font-size: 14px;
      color: #f38b3f;
    }
    .bg {
      display: none;
    }
    .h5-bg {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: auto;
      z-index: -1;
      min-height: 100%;
    }
  }
}
</style>
<style lang="less">
.el-loading-spinner {
  top: 0;
  margin-top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .circular {
    width: 20px;
    height: 20px;
  }
}
.btn {
  padding: 13px 36px;
  font-family: Roboto, Roboto;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  background: linear-gradient(97deg, #28a2fa 0%, #3071f2 100%);
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.15);
  border-radius: 12px 12px 12px 12px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  .el-loading-mask {
    border-radius: 12px 12px 12px 12px;
  }
  &.el-loading-parent--relative {
    border: none !important;
  }
}
</style>
<style>
* {
  /* font-family: Graphik, Helvetica, Arial, sans-serif; */
  font-family: Roboto;
}
</style>
