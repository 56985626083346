<template>
  <div class="warp">
    <div class="index-box">
      <navHeader />
      <div class="email-checked-warp">
        <div class="title">{{ $t("emailChecked.title") }}</div>
        <div class="sub-title">
          {{ $t("emailChecked.subscription") }}
        </div>
        <div class="email-checked-box">
          <div class="email-checked-box-title">
            {{ $t("emailChecked.subtitle") }}
          </div>
          <el-form label-position="top" :model="form" ref="form">
            <el-form-item
              prop="email"
              :rules="[
                {
                  required: true,
                  message: $t('emailChecked.emailMessage.tips1'),
                  trigger: 'blur',
                },
                {
                  type: 'email',
                  message: $t('emailChecked.emailMessage.tips2'),
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-input
                v-model="form.email"
                @blur="checkedEmail"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-button
              @click="submit"
              type="primary"
              class="btn"
              v-loading="loading"
              :disabled="disabled"
              >{{ $t("emailChecked.btn") }}</el-button
            >
          </el-form>
        </div>
        <div class="footer-des">
          {{ $t("emailChecked.tips") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { supportEmail } from "@/api/index";
import { mapState } from "vuex";

export default {
  name: "EmailChecked",
  data() {
    return {
      loading: false,
      disabled: true,
      form: {
        email: "",
      },
    };
  },

  computed: {
    ...mapState(["logoName", "domainInfo"]),
  },
  components: {},
  mounted() {},
  methods: {
    sentEmail() {
      this.loading = true;
      supportEmail({
        email: this.form.email,
        supportCode: this.domainInfo.code,
      })
        .then((res) => {
          this.loading = false;
          this.$router.push({
            path:'/verifyEmail',
            query:{
              email:this.form.email
            }
          });
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.disabled = false;
          this.sentEmail();
        } else {
          this.disabled = true;
        }
      });
    },
    checkedEmail() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.disabled = false;
        } else {
          this.disabled = true;
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media only screen and (min-width: 751px) {
  .email-checked-warp {
    max-width: 686px;
    margin-top: 40px;
    .title {
      font-weight: 600;
      font-size: 36px;
      color: #1f2021;
    }
    .sub-title {
      font-size: 18px;
      color: #1f2021;
      margin-top: 16px;
    }
    .email-checked-box {
      margin-top: 30px;
      padding: 30px 24px;
      background: rgba(255, 255, 255, 0.6);
      box-shadow: 0px 12px 36px 0px rgba(0, 0, 0, 0.1);
      border-radius: 12px 12px 12px 12px;
      padding-bottom: 52px;
      .email-checked-box-title {
        font-family: Roboto, Roboto;
        font-weight: 500;
        font-size: 24px;
        color: #1f2021;
        margin-bottom: 18px;
      }
      :deep(.el-input__inner) {
        // background: #e7ebec;
        height: 62px;
        border-radius: 12px;
      }
      .btn {
        margin-top: 36px;
        height: 60px;
        border-radius: 12px;
      }
    }
    .footer-des {
      font-size: 16px;
      color: #1f2021;
      margin-top: 43px;
    }
  }
}
@media only screen and (max-width: 750px) {
  .email-checked-warp {
    width: 100%;
    .title {
      font-weight: 600;
      font-size: 22px;
      color: #1f2021;
      margin-top: 20px;
    }
    .sub-title {
      font-size: 15px;
      color: #1f2021;
      margin-top: 10px;
    }
    .email-checked-box {
      margin-top: 20px;
      padding: 20px 15px;
      background: rgba(255, 255, 255, 0.6);
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 12px 12px 12px 12px;
      padding-bottom: 36px;
      .email-checked-box-title {
        font-weight: 500;
        font-size: 17px;
        color: #1f2021;
        margin-bottom: 12px;
      }
      :deep(.el-input__inner) {
        // background: #e7ebec;
        border-radius: 16px;
        height: 44px;
      }
      .btn {
        width: 100%;
        margin-top: 22px;
        border-radius: 16px;
        height: 44px;
      }
    }
    .footer-des {
      font-size: 14px;
      color: #1f2021;
      margin-top: 24px;
    }
  }
}
</style>