var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"warp"},[_c('div',{staticClass:"index-box"},[_c('navHeader'),_c('div',{staticClass:"email-checked-warp"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("payOrder.title")))]),_c('div',{staticClass:"sub-title"},[_vm._v(" "+_vm._s(_vm.$t("payOrder.subscription"))+" ")]),_c('div',{staticClass:"email-checked-box"},[_c('div',{staticClass:"email-checked-box-warp"},[_c('el-form',{ref:"form",attrs:{"label-position":"top","model":_vm.form}},[_c('el-form-item',{staticClass:"form-item",attrs:{"prop":"method","label":_vm.$t('payOrder.message.payment'),"rules":[
                {
                  required: true,
                  message: _vm.$t('payOrder.message.tips'),
                  trigger: 'blur',
                },
              ]}},[_c('el-select',{attrs:{"placeholder":_vm.$t('payOrder.message.tips2')},on:{"change":_vm.checkedEmail},model:{value:(_vm.form.method),callback:function ($$v) {_vm.$set(_vm.form, "method", $$v)},expression:"form.method"}},_vm._l((_vm.list),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{staticClass:"form-item",attrs:{"prop":"transactionId","label":_vm.$t('payOrder.message.transaction'),"rules":[
                {
                  required: true,
                  message: _vm.$t('payOrder.message.tips3'),
                  trigger: 'blur',
                },
              ]}},[_c('el-input',{on:{"blur":_vm.checkedEmail},model:{value:(_vm.form.transactionId),callback:function ($$v) {_vm.$set(_vm.form, "transactionId", $$v)},expression:"form.transactionId"}})],1),_c('el-form-item',[_c('div',{staticClass:"item-label",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t("payOrder.previewTitle"))+" ")]),_c('div',{staticClass:"preview-list"},_vm._l((_vm.payList),function(item,index){return _c('div',{key:index,staticClass:"preview-box"},[_c('el-image',{staticClass:"image-box",attrs:{"src":item,"preview-src-list":[item]}}),_c('div',{staticClass:"preview-icon"},[_vm._v(_vm._s(_vm.$t("payOrder.preview")))])],1)}),0)]),_c('el-button',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"btn",attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("payOrder.btn")))]),(_vm.tipsShow)?_c('div',{staticClass:"tips"},[_vm._v(" "+_vm._s(_vm.$t("payOrder.tips"))+" ")]):_vm._e()],1)],1)]),_c('div',{staticClass:"footer-des"},[_vm._v(" "+_vm._s(_vm.$t("payOrder.tips2"))+" ")])]),_c('Chat',{ref:"chat",attrs:{"title":_vm.$t('title')}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }