<template>
  <div class="customize-warp">
    <div class="name">
      {{ name }}
    </div>
    <div class="content">
      <img src="@/assets/cancelSuccess.svg" class="cancel-success" />
      <div class="tips-warp">
        <div class="tips-warp-title">
          <div>{{ $t("cancel.title") }}</div>
          <div>{{ $t("cancel.subTitle") }}</div>
        </div>
      </div>
      <div style="padding-top: 30px; width: 97%">
        <div v-html="$t('cancel.content')"></div>
      </div>
    </div>

    <div class="tips">
      <div class="right">
        <div>Thanks,</div>
        <div>{{ name }} Team</div>
      </div>
    </div>
    <Chat ref="chat" :title="$t('index.title')" />
  </div>
</template>

<script>
export default {
  name: "cancel",
  components: {},
  data() {
    return {
      name: "",
    };
  },
  computed: {
    descList() {
      return this.$t("index.desc.list");
    },
  },
  methods: {
    getChat() {
      this.$refs.chat.openChat();
    },
  },
  mounted() {
    console.log(this.$route);
    this.name = this.$route.query.businessSystemTitle || this.$t("index.title");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.customize-warp {
  min-width: 340px;
  max-width: 580px;
  margin: auto;
  padding-top: 40px;
}
.name {
  font-weight: bold;
  padding-bottom: 40px;
  height: 45px;
  font-size: 30px;
  display: flex;
  align-items: center;
}
.name img {
  margin-right: 10px;
}
.tips div {
  padding-bottom: 4px;
}
.tips .right {
  font-size: 14px;
  text-align: right;
}
.tips {
  text-align: right;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: rgb(14, 20, 20);
  -webkit-font-smoothing: antialiased;
  border-top: 1px solid rgb(210, 212, 222);
  padding-top: 20px;
}
.tips div {
  font-size: 13px;
  font-family: Graphik, Helvetica, Arial, sans-serif;
}
.tips-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: rgb(14, 20, 20);
  font-weight: bold;
}
.content {
  position: relative;
  margin-bottom: 40px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  margin-left: 9px;
  padding-bottom: 20px;
}
.content .bg {
  width: 102px;
  height: 102px;
  position: absolute;
  bottom: 9px;
  left: 76px;
}
.tips-warp {
  width: 90%;
  background: #cee8ff;
  border-radius: 10px;
  text-align: center;
  padding: 20px 20px;
  line-height: 24px;
  font-weight: 500;
  font-size: 14px;
}
.tips-warp-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 4px;
}
.send {
  text-decoration: underline;
  outline: none;
  cursor: pointer;
}
@media only screen and (min-width: 800px) {
  .tips-1 {
    white-space: nowrap;
  }
  .cancel-success {
    margin-bottom: 30px;
    width: 108px;
  }
}
@media only screen and (max-width: 750px) {
  .cancel-success {
    margin-bottom: 20px;
    width: 108px;
  }
  .content .bg {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 21px;
    left: -15px;
  }
  .tips {
    padding-left: 14px;
  }
  .tips-1 {
    white-space: wrap;
  }
}
.name img {
  height: 40px;
}
</style>
